<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.informacion_empleo') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="info_extra.empresa_trabajo"
                :label="$t('forms_flujo.form1_persona.UY.empresa_trabajo')"
                :error="errors.get('info_extra.empresa_trabajo')"
            >
                <el-input
                    ref="info_extra.empresa_trabajo"
                    v-model="formulario1.info_extra.empresa_trabajo"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.empresa_trabajo')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ramo_trabajo"
            :label="$t('forms_flujo.form1_persona.SP.rubro_laboral')"
            :error="errors.get('ramo_trabajo')"
          >
            <el-input
              ref="ramo_trabajo"
              v-model.number="formulario1.ramo_trabajo"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.rubro_laboral')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="info_extra.domicilio"
                :label="$t('forms_flujo.form1_persona.UY.domicilio')"
                :error="errors.get('info_extra.domicilio')"
            >
                <el-input
                    v-model="formulario1.info_extra.domicilio"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.domicilio')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="telefono_empresa"
                :label="$t('forms_flujo.form1_persona.UY.telefono_trabajo')"
                :error="errors.get('telefono_empresa')"
            >
                <el-input
                    v-model="formulario1.telefono_empresa"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.telefono_trabajo')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="fecha_ingreso"
                :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso_laboral')"
                :error="errors.get('fecha_ingreso')"
            >
                <el-input
                    v-model="formulario1.fecha_ingreso"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso_laboral')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="cargo_ocupa"
                :label="$t('forms_flujo.form1_persona.UY.puesto')"
                :error="errors.get('cargo_ocupa')"
            >
                <el-input
                    ref="cargo_ocupa"
                    v-model="formulario1.cargo_ocupa"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.puesto')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="ingreso_mensual"
                :label="$t('forms_flujo.form1_persona.UY.ingresos_mensuales')"
                :error="errors.get('ingreso_mensual')"
            >
                <el-input
                    ref="ingreso_mensual"
                    v-model="formulario1.ingreso_mensual"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.ingresos_mensuales')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="info_extra.otros_ingresos"
                  :label="$t('forms_flujo.form1_persona.UY.otros_ingresos')"
                  :error="errors.get('info_extra.otros_ingresos')"
              >
                  <el-input
                      ref="info_extra.otros_ingresos"
                      v-model="formulario1.info_extra.otros_ingresos"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.otros_ingresos')"
                      :disabled="true"
                  />
              </el-form-item>
          </el-col>
      </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
            <el-form-item
                prop="info_extra.fuentes_otros_ingresos"
                :label="$t('forms_flujo.form1_persona.UY.fuentes_otros_ingresos')"
                :error="errors.get('info_extra.fuentes_otros_ingresos')"
            >
                <el-input
                    v-model="formulario1.info_extra.fuentes_otros_ingresos"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.fuentes_otros_ingresos')"
                    :disabled="true"
                />
            </el-form-item>
            </el-col>

        </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import {getFromLocal} from "@/commons/utils/storage";

export default {
    name: 'PzPersonForm2FenoresteNew',
    components: { PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {
        return {
            reference: 'formulario1',
            marital_status: [],
            sucursales: [],
            formulario1: {
                ramo_trabajo: '',
                telefono_empresa: '',
                fecha_ingreso: '',
                cargo_ocupa: '',
                ingreso_mensual: '',
                info_extra: {
                    empresa_trabajo:'',
                    otros_ingresos: '',
                    fuentes_otros_ingresos: '',
                    domicilio: '',
                },
            },
        };
    },
    created() {
    },
    methods: {
        isReadOnly(data){
            let data_client = getFromLocal('data_client');
            if(data_client)
             return data_client[data]?true:false
            else
             return false;
        },
    },


}
</script>

