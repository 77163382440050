<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.datos_solicitante') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model.number="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model.number="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <pz-identity-document
                v-model="formulario1.identity"
                prop="identity"
                type="person"
                :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
                :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
                :number-hidden="condocumento"
            />

        </el-col>
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="domicilio"
                  :label="$t('forms_flujo.form1_persona.SP.domicilio')"
                  :error="errors.get('domicilio')"
              >
                  <el-input
                      ref="domicilio"
                      v-model.number="formulario1.domicilio"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.SP.domicilio')"
                      :disabled="true"
                  />
              </el-form-item>
          </el-col>
      </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
            <el-form-item
                prop="estudio"
                :label="$t('forms_flujo.form1_persona.UY.estudio')"
                :error="errors.get('estudio')"
            >
                <el-input
                    v-model="formulario1.estudio"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.estudio')"
                    :disabled="true"
                />
            </el-form-item>
            </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="fecha_nacimiento"
                :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
                :error="errors.get('fecha_nacimiento')"
            >
                <el-date-picker
                    ref="fecha_nacimiento"
                    v-model="formulario1.fecha_nacimiento"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="dd/MM/yyyy"
                    :picker-options="pickerOptions1"
                    :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="lugar_nacimiento"
                :label="$t('forms_flujo.form1_persona.UY.lugar_nacimiento')"
                :error="errors.get('lugar_nacimiento')"
            >
                <el-input
                    v-model="formulario1.lugar_nacimiento"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.lugar_nacimiento')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="telefono_auxiliar"
                :label="$t('forms_flujo.form1_persona.UY.telefono_auxiliar')"
                :error="errors.get('telefono_auxiliar')"
            >
                <el-input
                    ref="telefono_auxiliar"
                    v-model="formulario1.telefono_auxiliar"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.telefono_auxiliar')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <el-form-item
                prop="telefono_recados"
                :label="$t('forms_flujo.form1_persona.UY.telefono_recados')"
                :error="errors.get('telefono_recados')"
            >
                <el-input
                    ref="telefono_recados"
                    v-model="formulario1.telefono_recados"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.telefono_recados')"
                    :disabled="true"
                />
            </el-form-item>
        </el-col>
          <el-col
              :xl="{span: 12}"
              :lg="{span: 12}"
              :md="{span: 12}"
              :sm="{span: 24}"
              :xs="{span: 24}"
          >
              <el-form-item
                  prop="telefono_Celular"
                  :label="$t('forms_flujo.form1_persona.UY.telefono_Celular')"
                  :error="errors.get('telefono_Celular')"
              >
                  <el-input
                      ref="telefono_Celular"
                      v-model="formulario1.telefono_Celular"
                      :type="'text'"
                      :placeholder="$t('forms_flujo.form1_persona.UY.telefono_Celular')"
                      :disabled="true"
                  />
              </el-form-item>
          </el-col>
      </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
            <el-form-item
                prop="email"
                :label="$t('forms_flujo.form1_persona.UY.email')"
                :error="errors.get('email')"
            >
                <el-input
                    v-model="formulario1.email"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form1_persona.UY.email')"
                    :disabled="true"
                />
            </el-form-item>
            </el-col>
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="antiguedad_domicilio"
                    :label="$t('forms_flujo.form1_persona.UY.antiguedad_domicilio')"
                    :error="errors.get('antiguedad_domicilio')"
                >
                    <el-input
                        ref="antiguedad_domicilio"
                        v-model="formulario1.antiguedad_domicilio"
                        :type="'text'"
                        :placeholder="$t('forms_flujo.form1_persona.UY.antiguedad_domicilio')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="edad"
                    :label="$t('forms_flujo.form1_persona.UY.edad')"
                    :error="errors.get('edad')"
                >
                    <el-input
                        v-model="formulario1.edad"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.edad')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="estado_civil_id"
                    :label="$t('forms_flujo.form1_persona.UY.civil_state')"
                    :error="errors.get('estado_civil_id')"
                >
                    <el-input
                        v-model="formulario1.estado_civil_id"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.estado_civil_id')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="regimen_patrimonial"
                    :label="$t('forms_flujo.form1_persona.UY.regimen_patrimonial')"
                    :error="errors.get('regimen_patrimonial')"
                >
                    <el-input
                        v-model="formulario1.regimen_patrimonial"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.regimen_patrimonial')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="nombre_conyugue"
                    :label="$t('forms_flujo.form1_persona.UY.nombre_conyugue')"
                    :error="errors.get('nombre_conyugue')"
                >
                    <el-input
                        v-model="formulario1.nombre_conyugue"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.nombre_conyugue')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="num_socio_conyugue"
                    :label="$t('forms_flujo.form1_persona.UY.num_socio_conyugue')"
                    :error="errors.get('num_socio_conyugue')"
                >
                    <el-input
                        v-model="formulario1.num_socio_conyugue"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.num_socio_conyugue')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="personas_viven_en_casa"
                    :label="$t('forms_flujo.form1_persona.UY.personas_viven_en_casa')"
                    :error="errors.get('personas_viven_en_casa')"
                >
                    <el-input
                        v-model="formulario1.personas_viven_en_casa"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.personas_viven_en_casa')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="propiedad_vivienda"
                    :label="$t('forms_flujo.form1_persona.UY.propiedad_vivienda')"
                    :error="errors.get('propiedad_vivienda')"
                >
                    <el-input
                        v-model="formulario1.propiedad_vivienda"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.propiedad_vivienda')"
                        :disabled="true"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xl="{span: 12}"
                :lg="{span: 12}"
                :md="{span: 12}"
                :sm="{span: 24}"
                :xs="{span: 24}"
            >
                <el-form-item
                    prop="destino_prestamo"
                    :label="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
                    :error="errors.get('destino_prestamo')"
                >
                    <el-input
                        v-model="formulario1.destino_prestamo"
                        :type="'text'"
                        auto-complete="no"
                        :placeholder="$t('forms_flujo.form1_persona.UY.destino_prestamo')"
                    />
                </el-form-item>
            </el-col>
        </el-row>

    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {getFromLocal} from "@/commons/utils/storage";

export default {
    name: 'PzPersonForm1FenoresteNew',
    components: { PzFormFlujo, PzIdentityDocument},
    mixins: [PzBaseForm1],
    data() {
        return {
            reference: 'formulario1',
            marital_status: [],
            sucursales: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                estudio: '',
                fecha_nacimiento: '',
                lugar_nacimiento: '',
                telefono_auxiliar: '',
                telefono_recados: '',
                telefono_Celular: '',
                email: '',
                antiguedad_domicilio: '',
                edad:'',
                estado_civil_id:'',
                regimen_patrimonial:'',
                nombre_conyugue:'',
                num_socio_conyugue:'',
                personas_viven_en_casa:'',
                propiedad_vivienda:'',
                destino_prestamo:'',
                domicilio: '',
                identity: {document: 1},
            },
            rules_formulario1: {
                destino_prestamo: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created() {
    },
    computed:{
        condocumento(){
            var docmun = localStorage.getItem('docnum');
            if(docmun){
                return    true;
            }

            return false
        }
    },
    methods: {
        isReadOnly(data){
            let data_client = getFromLocal('data_client');
            if(data_client)
             return data_client[data]?true:false
            else
             return false;
        },
    },


}
</script>

