<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.resumen_ingresos') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
        <el-row
            :gutter="20"
            class="row justify-content-center"
        >
            <b class="pz-font-size-lg">
                {{ $t('forms_flujo.form2_persona.UY.ingresos') }}
            </b>
        </el-row>
        <el-row :gutter="20">
            <el-table
                    :data="tableIngresos"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="fuente"
                        label="Fuente"
                       >
                </el-table-column>
                <el-table-column
                        prop="monto"
                        label="Monto"
                        >
                </el-table-column>
            </el-table>
        </el-row>
        <br/>
        <el-row
            :gutter="20"
            class="row justify-content-center"
        >
            <b class="pz-font-size-lg">
                {{ $t('forms_flujo.form2_persona.UY.gastos') }}
            </b>

        </el-row>
      <el-row :gutter="20">
          <el-table
                  :data="tableData"
                  stripe
                  style="width: 100%">
              <el-table-column
                      prop="fuente"
                      label="Fuente"
                      >
              </el-table-column>
              <el-table-column
                      prop="monto"
                      label="Monto">
              </el-table-column>
          </el-table>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import {getFromLocal} from "@/commons/utils/storage";
import currency from "@/commons/filters/other/currency";

export default {
    name: 'PzPersonForm3FenoresteNew',
    components: { PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {
        return {
            reference: 'formulario1',
            tableIngresos :[],
            tableData: [],
            formulario1: {

            },
        };
    },
    created() {
        let data_client = getFromLocal('data_client');
        this.tableIngresos=[{
            fuente: 'Fijos',
             monto: '$ '+currency(data_client['monto_ingresos_fijos'],'',2)
        },
        {
            fuente: 'Variables',
              monto: '$ '+currency(data_client['monto_ingresos_variables'],'',2)
        },
        {
            fuente: 'Total',
              monto: '$ '+currency(data_client['monto_total_fijos'],'',2)
        }];
        var object1 = data_client['gastos'];
        for (const [key, value] of Object.entries(object1)) {
            this.tableData.push({
                fuente:   this.$t('forms_flujo.form2_persona.UY.'+key) ,
                monto: '$ '+currency(value=='null'?0:value,'',2)
            });

        }
    },
    methods: {
        isReadOnly(data){
            let data_client = getFromLocal('data_client');
            if(data_client)
             return data_client[data]?true:false
            else
             return false;
        },
    },


}
</script>

